import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/app/assets/applestore.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/app/assets/arrow.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/app/assets/atendimentoAoCliente.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/app/assets/googleplay.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/cards/MainDestionations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_9df34ef6/src/components/cards/TicketsWithBestPrices.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/cards/WhyEmbarcaAiImgs/compare.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/cards/WhyEmbarcaAiImgs/economia.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/cards/WhyEmbarcaAiImgs/maisdestinos.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/cards/WhyEmbarcaAiImgs/seguranca.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/forms/Banner2Lines.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_9df34ef6/src/components/header/EmbarcaAiHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_9df34ef6/src/components/home/HomeSendGTM.tsx");
